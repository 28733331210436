<template>
	<div class="pb-21">
		<template v-if="page">
			<template v-if="!page.media[0] || page.heroLayout[0] == 'showImageBelowTitle'">
				<div class="grid grid-cols-12 component max-md:pb-0">
					<div class="col-span-12 mt-24" :class="{'md:mb-21': page.media.length}">
						<h1 class="headline-big"><span v-html="page.title"></span> <span class="font-serif">/</span> <span class="text-gray-dark">{{page.titleEnglish}}</span></h1>
					
						<template v-if="page.media[0] && page.heroLayout[0] == 'showImageBelowTitle'">
							<div class="pt-8">
								<Media :data="page.media[0]" class="block w-full mt-4 md:mt-20"/>
							</div>
						</template>
					</div>
				</div>
			</template>
				
			<template v-if="page.media[0] && page.heroLayout[0] != 'showImageBelowTitle'">
				<div class="grid grid-cols-12 page-hero component">
					<div class="col-span-12 mt-14 md:mt-12 relative">
						<div class="aspect-[325/200] md:aspect-[1608/718]" :class="`image image-${page.imageLayout}`">
							<Media :data="page.media[0]" class="block h-full mx-auto"/>
						</div>
						
					
						<div class="relative z-10 -mb-1.5 w-full flex flex-row justify-between items-start">
							<div class="inline-block pr-3 md:pr-19 pt-3 md:pt-18 -mt-[2.73rem] md:-mt-[7rem] max-w-[86%] bg-white">
								<h1 class="headline-big"><span v-html="page.title"></span> <span class="font-serif">/</span> <span class="text-gray-dark">{{page.titleEnglish}}</span></h1>
							</div>
						</div>
					</div>
					
				</div>
			</template>

			<template v-for="(entry, index) in page.sections" :key="index">
				<component :is="entry.__typename.replace('_Entry', '')" :data="entry"/>
			</template>
				
		</template>
	</div>
</template>

<script setup>
import { pageQuery } from '@/api/queries'
const config = useRuntimeConfig();
const route = useRoute();

// definePageMeta({
// 	async validate({ params }) {
// 		const query = pageQuery(params.slug[0])
// 		const config = useRuntimeConfig();
// 		const token = null;
// 		const { data: res } = await useFetch(config.public.API, {
// 			params: { query, token },
// 		});

// 		if (
// 			!res.value
// 		) {
// 			return createError({
// 				statusCode: 404,
// 				message: 'Page not found',
// 			})
// 		}

// 		return true
// 	}
// })

const page = ref(null)

// Build the URL, with `query` and `token` params:
const query = pageQuery(route.params.slug[0])
const token = route.query.token;

const getData = async () => {
	const { data: gql } = await useFetch(config.public.API, {
		params: { query, token },
	});

	// Extract the first page from the response:
	if (gql.value) {
		page.value = gql.value.data.pagesEntries[0];
	}
}

// Fetch data on SSR
await getData()

onMounted(() => {
    getData();
});

useHead({
	title: `Sydhavn Teater — ${page?.value?.title} / ${page?.value?.titleEnglish}`,
	meta: [
		{ hid: 'og:title', property: 'og:title', content: `Sydhavn Teater — ${page?.value?.title} / ${page?.value?.titleEnglish}` },
		{ hid: 'og:image', property: 'og:image', content: page?.value?.media[0]?.url },
	],
});
</script>

<style lang="scss" scoped>
.page-hero {
	:deep(.media) {
        @apply flex flex-row;
    }
}

.image {
	&-cover :deep(.media) img {
        @apply object-cover object-center w-full;
    }

    &-left :deep(.media) img {
        @apply object-contain object-left;
    }

    &-right :deep(.media) img {
        @apply object-contain object-right;
    }

    &-center :deep(.media) img {
        @apply object-contain object-center;
    }
}
</style>